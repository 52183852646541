<template>
<div>
    <div class="m">
        <div class="flow">
            
            <h3 class="fl-tit">
                阶段一：入驻前准备
            </h3>
            <h6 style="color:#999;font-size:12px">暂行中...</h6>
            <ul class="fl-con clearfix">
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">了解招商信息</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）品牌：微在线枚举的重点招募品牌、您也可以推荐优质品牌给微在线，部分类目不限定品牌招商。
                    </p>
                    <p class="item-txt">
                        2）企业：合法登记的公司，工体工商户或个人（限拼购、优创店铺），并且能够提供微在线入驻要求的所有相关文件。
                    </p>
                    <p class="item-txt">
                        3）资质要求详见：《微在线开放平台招商基础资质标准》及各类目资质标准。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">准备资质材料</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）点击查询入驻所需资质和费用。
                    </p>
                    <p class="item-txt">
                        2）准备的资料请加盖开店公司公章（鲜章）。
                    </p>
                    <p class="item-txt">
                        3）如申请材料缺少，会退回给您重新提交，建议您事先准备齐全资料，一次性通过审核。
                    </p>
                </li>
           
            </ul>
            <h3 class="fl-tit mt62">
                阶段二：入驻申请
            </h3>
            <ul class="fl-con clearfix ">
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">注册账号</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）在进入入驻流程前，请注册微在线个人用户账号，用于入驻关联。
                    </p>
                    <p class="item-txt">
                        2）进入微在线用户中心，验证手机和邮箱,以保证您入驻信息的安全。
                    </p>
                    <p class="item-txt">
                        <!-- <a href="http://apply.shop.jd.com/" target="_blank">“马上入驻”</a> -->
                        3）进入商家入驻页面,进入入驻流程。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">填写公司信息</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）确认入驻协议、查看入驻须知。
                    </p>
                    <p class="item-txt">
                        2）录入开店联系人的信息，便于在入驻过程中遇到任何问题，我们可以第一时间联系到您。
                    </p>
                    <p class="item-txt">
                        3）填写公司信息，包含营业执照、组织机构代码和经营信息。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">填写店铺信息</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）填写店铺信息，包含店铺类型、经营类目和品牌。提交公司和类目品牌相关资质。点击查看入驻所需资质。
                    </p>
                    <p class="item-txt">
                        2）选择您希望使用的店铺名称和域名。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">签署合同提交入驻申请</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）确认在线服务协议，提交入驻申请。
                    </p>
                </li>
            </ul>
            <h3 class="fl-tit mt98">
                阶段三：审核
            </h3>
            <ul class="fl-con clearfix ">
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">资质初审</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）资质真实有效。
                    </p>
                    <p class="item-txt">
                        2）规模达到入驻要求。
                    </p>
                    <p class="item-txt">
                        3）授权有效，链路完整。
                    </p>
                    <p class="item-txt">
                        4）生产、经营范围、产品安全性 资质完整 符合国家行政法 规许可要求。
                    </p>
                    <p class="item-txt">
                        5）审核周期：7个工作日。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">招商复审</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）招商复审，审核周期：7个工作日。
                    </p>
                    <p class="item-txt">
                        2）店铺授权，大概3个工作日。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">审核进度查询</span>
                    </h3>
                    <!-- 商家可查询<a href="http://apply.shop.jd.com/" target="_blank">入驻审核进度</a>。 -->
                    <p class="item-txt pt31">
                        1）入驻过程中的重要信息，我们会以邮件形式发送至您在入驻联系人信息中填写的邮箱。
                    </p>
                </li>
            </ul>
            <h3 class="fl-tit mt98">
                阶段四：开店任务
            </h3>
            <ul class="fl-con clearfix ">
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">联系人和地址信息维护</span>
                    </h3>
                    <p class="item-txt pt31">
                        1）联系人信息：完善不同管理角色联系信息。
                    </p>
                    <p class="item-txt">
                        2）常用地址维护：退换货地址信息填写维护。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen">账号安全验证 </span>
                    </h3>
                    <p class="item-txt pt31">
                        1）账号安全验证：设置账号绑定手机、邮箱 ( 可用于重设找回密码 )。
                    </p>
                </li>
                <li class="flow-item">
                    <h3 class="item-tit">
                        <span class="txt-cen ml10">缴费开店</span>
                    </h3>
                    <p class="item-txt  pt31">
                        1）在线支付平台使用费、质保金，完成缴费。点击查看资费标准。
                    </p>
                    <p class="item-txt">
                        2）微在线确认缴费无误。
                    </p>
                    <p class="item-txt">
                        3）店铺状态变为开通，您可以登录商家后台开始正常经营。点击查看开店指引。
                    </p>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
ul li{
    list-style-type: none
}
.m {
    width: 1210px;
    margin: 0 auto;
    text-align: left;
}
.flow .fl-tit {
    font-size: 24px;
    margin-left: 100px;
    padding-top: 14px;
    text-align: center;
}
.fl-con{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 1 auto;
    flex-wrap: wrap;
    
}
.flow-item{
    width: 30%;
    padding: 22px;
    box-sizing: border-box;
    margin: 10px 1.5%;
    font-size: 13px;
    border: 1px solid #dedede;
    border-radius: 5px;
}
.flow .item-tit {
    font-size: 16px;
    color: #f44336;
    margin-left: 25px;
    height: 40px;
    position: relative;
}
</style>
